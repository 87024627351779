import crud from "./crud";
import crud_service from "@/services/crud";

const path = "users";
const struct = {
  id: null,
  username: "",
  first_name: "",
  last_name: "",
  lang: "fr",
  is_staff: false,
  is_superuser: false,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
  all: [],
  user_actual_regions: []
};

const getters = {
  ...generic.getters,
};

const mutations = {
  setAll(state, items) {
    state.all = items;
  },

  setItems: generic.mutations.setItems,
  setIsLoading: generic.mutations.setIsLoading,
  setIsSearching: generic.mutations.setIsSearching,
  setPagination: generic.mutations.setPagination,
  setSearch: generic.mutations.setSearch,
  setUsers(state, users) {
    state.user_actual_regions = users;
  },
};

const actions = {
  searchItems: generic.actions.searchItems,
  setSearch: generic.actions.setSearch,
  setEmptySearch: generic.actions.setEmptySearch,
  getItems: generic.actions.getItems,
  getAll({commit}) {                                                                                                  
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {crud_service.fetch(path).then((items)=>{
      commit("setAll",items.results);
      resolve(items.results);
      commit('setIsLoading', false);
    }).catch((e)=>{
      reject();
    })})
  },

  async fetchUsersByRegions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      crud_service
        .fetch(`${path}/get_users_by_region`, payload)
        .then((response) => {
          commit("setUsers", response);
          resolve(response.data);
        })
        .catch((error) => {
          console.error("Error fetching users by regions:", error);
          reject(error);
        });
    });
  },
  
  
  

  initSearch({ commit }, { item_ids }) {
    if (!Array.isArray(item_ids)) {
      item_ids = [item_ids];
    }
    commit("setIsSearching", true);

    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/search`, { id: item_ids })
        .then((items) => {
          commit("setSearch", items);
          commit("setIsSearching", false);
          resolve();
        })
        .catch(() => {
          commit("setIsSearching", false);
        });
    });
  },
//  vpn({commit}, payload = {}) {
//    // TODO: specify MOB or PC, QR or txt
//    return new Promise((resolve) => {
//      crud_service.fetch(`${path}/vpn`, {params:payload}).then((items) => {
//        console.log(items)
//        resolve(items);
//      });
//    });
//  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
