<template>
    <v-btn
        :color="item.worst_status_color"
        class="machine-btn mx-2"
        @click="goToMachineProducer(item, power_station_name)"
        elevation="2"
    >
        <div class="btn-content">
            <div class="title-display">
                <span class="title-text">{{ truncatedDisplayName(power_station_name) }}</span>
            </div>
            
            <div class="power-display">
                <span class="font-weight-bold">{{ roundedPower(item.total_power) }} kW</span>
            </div>
            
            <div class="icons-display">
                <v-icon v-if="item.total_limitations > 0" color="black" class="icon-turtle">
                mdi-tortoise
                </v-icon>
                <v-icon v-if="item.total_alarms > 0" color="black" class="icon-bell">
                mdi-bell-alert
                </v-icon>

                <v-icon v-if="getMaxCriticality(item.producers) == 1" color="black">
                mdi-speedometer-slow
                </v-icon>
                <v-icon v-if="getMaxCriticality(item.producers) == 2" color="black">
                mdi-speedometer-medium
                </v-icon>
            </div>
        </div>
    </v-btn>
</template>
<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        power_station_name: {
            type: String,
            required: true
        }
    },
    computed: {
        isWind() {
            return this.item && this.item.type === 'wind';
        },
        isSolar() {
            return this.item && this.item.type === 'solar';
        },
    },
    methods: {
        goToMachineProducer(item, power_station_name) {
            const payload = {[power_station_name]: item,};
            this.$store.dispatch('machines/setSelectedItem', payload);
            if(this.isWind){
                this.$router.push({ name: 'machine_wind_producer_view', query: { power_station_id: [Number(item.id)]} });
            }
            else if(this.isSolar){
                this.$router.push({ name: 'machine_solar_producer_view', query: { power_station_id: [Number(item.id)]} });
            }
        },
        roundedPower(power) {
            return power ? power.toFixed(2) : '0.00';
        },
        truncatedDisplayName(name) {
            const maxLength = 20;
            return name.length > maxLength ? name.substring(0, maxLength) + '...' : name;
        },
        getMaxCriticality(producers) {
            let maxCriticality = -1;
            for (const [producer_id, producer] of Object.entries(producers)){
                producer.rules.forEach(rule => {
                    const ruleObject = Object.values(rule)[0];

                    if (ruleObject.criticality > maxCriticality && ruleObject.criticality != 0) {
                    maxCriticality = ruleObject.criticality;
                    }
                });
            }
            return maxCriticality;
        }
    }
};
</script>

<style scoped>
    .machine-btn {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .btn-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .title-display {
        margin-top: 12px;
        margin-bottom: 12px;
        color: black;
    }

    .power-display {
        margin-bottom: 12px;
        color: black;
    }

    .producer-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .producer-name {
        font-weight: bold;
        color: black;
    }

    .producer-icons {
        display: flex;
        gap: 10px;
        margin-top: 8px;
    }

    .producer-row {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .icons-display {
        display: flex;
        justify-content: center;
        gap: 8px;
    }
</style>

