import crud from "./crud";
import crud_service from "@/services/crud";
import { isNone } from "@/functions";

const path = "machines";
const struct = {
    name: "",
    description: "",
    ilk: "",
    start: "",
    end: "",
    is_locked: "",
    is_remote: null,
    user_id: null,
    producer_id: null,
};
const generic = crud(path, struct);

const state = {
    ...generic.state,
    item: null,
};

const getters = {
    ...generic.getters,
};

const mutations = {
    ...generic.mutations,
    setItem(state, item) {
        state.item = item;
    },
};

const actions = {
    ...generic.actions,
    setSelectedItem({ commit }, item) {
        commit('setItem', item);
    },

    get_wind_status_producer_items({ commit }, payload) {
        const url = `machines/wind_status`;
        return new Promise((resolve, reject) => {
            crud_service.fetch(url, payload)
                .then((data) => {
                    if (data && typeof data === 'object') {
                        resolve(data);
                    } else {
                        console.error("Expected a dictionary but received:", data);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        });
    },

    get_solar_status_producer_items({ commit }, payload) {
        const url = `machines/solar_status`;
        return new Promise((resolve, reject) => {
            crud_service.fetch(url, payload)
                .then((data) => {
                    if (data && typeof data === 'object') {
                        resolve(data);
                    } else {
                        console.error("Expected a dictionary but received:", data);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
