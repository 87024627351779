<template>
  <v-container>
    <div style="display: none">
      <EditDialog
        v-bind:model="editInternalRepair"
        v-bind:moduleName="'internal_repairs'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('edit_item')"
        @save="saveIREvent"
        @close="cleanupIRDialog"
        ref="editDialogIR"
      >
        <template v-slot:edit_content>
          <InternalRepairForm v-bind:askRepairedAt="false"></InternalRepairForm>
        </template>
      </EditDialog>

      <EditDialog
        v-bind:model="editToStock"
        v-bind:moduleName="'affectations'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('edit_item')"
        @save="saveToStock"
        @close="cleanupStockDialog"
        ref="editDialogToStock"
      >
        <template v-slot:edit_content>
          <AffectationForm 
                  v-bind:askKind="false"
          ></AffectationForm>
        </template>
      </EditDialog>

      <EditDialog
        v-bind:model="editExternalRepair"
        v-bind:moduleName="'external_repairs'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('edit_item')"
        @save="saveEREvent"
        @close="cleanupERDialog"
        ref="editDialogER"
      >
        <template v-slot:edit_content>
          <ExternalRepairForm 
          v-bind:askReceivedAt="false"
          @subFormUpdate="extRepUpdate"
          ></ExternalRepairForm>
          <v-card 
            v-show='askNextAff'
            :title="$filters.capitalize($t('affectations.choose_type'))"
            >
            <AffectationForm
                :showSerial = 'false'
                :unitIsReturning = 'true'
            ></AffectationForm>
          </v-card>
        </template>
      </EditDialog>

      <EditDialog
        v-bind:model="editTask"
        v-bind:moduleName="'todos'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('edit_item')"
        @save="saveTaskEvent"
        @close="cleanupTaskDialog"
        ref="editDialogTask"
      >
        <template v-slot:edit_content>
          <TodoForm v-bind:askInterventionReport="false"></TodoForm>
        </template>
      </EditDialog>

      <EditDialog
        v-bind:model="editTaskDate"
        v-bind:moduleName="'todos'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('edit_item')"
        v-bind:smallWidth="true"
        @save="saveTaskDateEvent"
        @close="cleanupTaskDateDialog"
        ref="editDialogTaskDate"
      >
        <template v-slot:edit_content>
          <DatePicker
            v-bind:label="'todos.fulfilled_at'"
            v-bind:value="task_edit.fulfilled_at"
            @dateSelected="task_edit.fulfilled_at = $event"
            :key="task_edit.fulfilled_at"
          >
          </DatePicker>
        </template>
      </EditDialog>
    </div>
    <TodosSearch @filterToP="changeP($event)"/>

    <v-card class="mx-auto my-3">
      <v-toolbar flat color="white" elevation="1">

        <v-toolbar-title style="font-weight: bolder" class="flex text-center"
          >{{$filters.capitalize( $t(`todos.label`, 1) ) }} &mdash;
          {{$filters.capitalize( $t(`internal_repairs.label`, 10) ) }}
        </v-toolbar-title>


        <v-btn color="primary-darken-1" @click="exportData('internal')">
          <v-icon small>mdi-file-export-outline</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headersInternal"
        :items="internal_affs"
        item-key="pk"
        :no-data-text="$filters.capitalize($t('todos.no_data_text_ir') )"
        :items-per-page-options="footerProps['items-per-page-options']"
        :items-per-page-text="footerProps['items-per-page-text']"
        :row-props='bgClass' 
        elevation="2"
      >
        
        <template v-slot:item.internal_repair.created_at="{ item }">
          <tr>
              {{$filters.formatDate(item.internal_repair.created_at)}}
          </tr>
        </template>

        <template v-slot:item.actions="{ item }">
              <v-tooltip location="top"  :text="$filters.capitalize( $t('units.actions.view_item'))">
                <template v-slot:activator="{ props }">
                  <v-icon small v-bind="props" class="mr-2" @click="viewUnit(item)"
                    >mdi-eye-outline</v-icon
                  >
                </template>
              </v-tooltip>

          <v-tooltip location="top"  :text="$filters.capitalize( $t('internal_repairs.actions.edit_item'))">
            <template v-slot:activator="{ props }">
              <v-icon small v-bind="props" class="mr-2" @click="editIRBtn(item)"
                >mdi-pencil
              </v-icon>
            </template>
          </v-tooltip>

          <v-tooltip location="top" :text="$filters.capitalize( $t('todos.actions.to_stock') )">
            <template v-slot:activator="{ props }">
              <v-icon
                small
                v-bind="props"
                class="mr-2"
                @click="internal_repaired(item)"
                >mdi-file-cabinet
              </v-icon>
            </template>
          </v-tooltip>

          <v-tooltip location="top" :text="$filters.capitalize( $t('todos.actions.to_trash') )">
            <template v-slot:activator="{ props }">
              <v-icon
                small
                v-bind="props"
                class="mr-2"
                @click="internal_trashed(item)"
                >mdi-delete-empty-outline
              </v-icon>
            </template>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mx-auto my-3">
      <v-toolbar flat color="white" elevation="1">

        <v-toolbar-title style="font-weight: bolder" class="flex text-center"
          >{{$filters.capitalize( $t(`todos.label`, 1) ) }} &mdash;
          {{$filters.capitalize( $t(`external_repairs.label`, 10) ) }}
        </v-toolbar-title>

        <v-btn color="primary-darken-1" @click="exportData('external')">
          <v-icon small>mdi-file-export-outline</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headersExternal"
        :items="external_affs"
        item-key="pk"
        :no-data-text="$filters.capitalize($t('todos.no_data_text_er') )"
        :items-per-page-options="footerProps['items-per-page-options']"
        :items-per-page-text="footerProps['items-per-page-text']"
        :row-props='bgClass' 
        elevation="2"
        >

        <template v-slot:item.external_repair.created_at="{ item }">
              {{$filters.formatDate(item.external_repair.created_at)}}
        </template>
        <template v-slot:item.external_repair.sent_at="{ item }">
              {{$filters.formatDate(item.external_repair.sent_at)}}
        </template>
        <template v-slot:item.external_repair.quotation_at="{ item }">
              {{$filters.formatDate(item.external_repair.quotation_at)}}
        </template>
        <template v-slot:item.external_repair.agreed_at="{ item }">
              {{$filters.formatDate(item.external_repair.agreed_at)}}
        </template>
        <template v-slot:item.external_repair.returned_at="{ item }">
              {{$filters.formatDate(item.external_repair.returned_at)}}
      </template>

        <template v-slot:item.actions="{ item }">
              <v-tooltip location="top"  :text="$filters.capitalize( $t('units.actions.view_item'))">
                <template v-slot:activator="{ props }">
                  <v-icon small v-bind="props" @click="viewUnit(item)"
                    >mdi-eye-outline</v-icon
                  >
                </template>
              </v-tooltip>
          <v-tooltip location="top"  :text="$filters.capitalize( $t('external_repairs.actions.edit_item'))">
            <template v-slot:activator="{ props }">
              <v-icon small v-bind="props" class="mr-2" @click="editERBtn(item)"
                >mdi-pencil
              </v-icon>
            </template>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mx-auto my-3">
      <v-toolbar flat color="white" elevation="1">
        <v-toolbar-title style="font-weight: bolder" class="flex text-center"
          >{{$filters.capitalize( $t(`todos.label`, 1) ) }} &mdash;
          {{$filters.capitalize( $t(`todos.task`, 10) ) }}
        </v-toolbar-title>

        <v-btn
          color="primary-darken-1"
          class="mb-2"
          @click="editTaskBtn(undefined)"
        >
          {{$filters.capitalize( $t(`todos.actions.add_item`) ) }}
        </v-btn>

        <v-btn color="primary-darken-1" @click="exportData('done')">
          <v-icon small>mdi-file-export-outline</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headersTask"
        :items="tasks"
        item-key="pk"
        :no-data-text="$filters.capitalize($t('todos.no_data_text_er') )"
        :items-per-page-options="footerProps['items-per-page-options']"
        :items-per-page-text="footerProps['items-per-page-text']"
        :row-props='bgClass' 
        elevation="2"
      >
        <template v-slot:item.created_at="{ item }">
              {{$filters.formatDate(item.created_at)}}
        </template>
        <template v-slot:item.fulfilled_at="{ item }">
            {{$filters.formatDate( item.fulfilled_at ) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip location="top"  :text="$filters.capitalize( $t('actions.edit'))">
            <template v-slot:activator="{ props }">
              <v-icon small v-bind="props" class="mr-2" @click="editTaskBtn(item)"
                >mdi-pencil
              </v-icon>
            </template>
          </v-tooltip>

          <v-tooltip location="top" :text="$filters.capitalize( $t('todos.actions.close') )">
            <template v-slot:activator="{ props }">
              <v-icon small v-bind="props" class="mr-2" @click="close_todo(item)"
                >mdi-text-box-check-outline
              </v-icon>
            </template>
          </v-tooltip>

          <v-tooltip location="top" :text="$filters.capitalize( $t('todos.actions.to_trash') ) ">
            <template v-slot:activator="{ props }">
              <v-icon small v-bind="props" class="mr-2" @click="remove_todo(item)"
                >mdi-delete-empty-outline
              </v-icon>
            </template>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mx-auto my-3">
      <v-toolbar flat color="white" elevation="1">

        <v-toolbar-title style="font-weight: bolder" class="flex text-center"
          >{{$filters.capitalize( $t(`todos.label`, 1) ) }} &mdash;
          {{$filters.capitalize( $t(`todos.task`, 10) ) }}
          {{$filters.capitalize( $t(`todos.done`, 10) ) }}
        </v-toolbar-title>

        <v-btn color="primary-darken-1" @click="exportData('done')">
          <v-icon small>mdi-file-export-outline</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headersTaskDone"
        :items="tasksDone"
        item-key="pk"
        :no-data-text="$filters.capitalize($t('todos.no_data_text_er') )"
        :items-per-page-options="footerProps['items-per-page-options']"
        :items-per-page-text="footerProps['items-per-page-text']"
        elevation="2"
      >
        <template v-slot:item.created_at="{ item }">
              {{$filters.formatDate(item.created_at)}}
        </template>
        <template v-slot:item.fulfilled_at="{ item }">
            {{$filters.formatDate( item.fulfilled_at ) }}
        </template>

      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";

import {
  capitalize,
  isNone,
  slicyUpdate,
  slicyRemove,
  alertPopup,
} from "@/functions.js";
import utils from "@/mixins/utils";
import EditDialog from "@/components/EditDialog";
import InternalRepairForm from "@/components/InternalRepairForm";
import AffectationForm from "@/components/AffectationForm";
import ExternalRepairForm from "@/components/ExternalRepairForm";
import TodoForm from "@/components/TodoForm";
import DatePicker from "@/components/DatePicker";
import TodosSearch from "@/components/todos/TodosSearch.vue";

export default {
  components: {
    EditDialog,
    InternalRepairForm,
    AffectationForm,
    ExternalRepairForm,
    TodoForm,
    DatePicker,
    TodosSearch
  },

  mixins: [utils],

  computed: {
    // ALREADY DEFINED IN UTILS MIXINS
    //items: function () {
    //  return this.$store.state["affectations"].items;
    //},
    external_repair_edit: function () {
      return this.$store.state["external_repairs"].edit;
    },
    internal_repair_edit: function () {
      return this.$store.state["internal_repairs"].edit;
    },
    task_edit: function () {
      return this.$store.state["todos"].edit;
    },
    user: function () {
      return this.$store.getters.user;
    },
  },

  // REMOVED as loadList is already being triggered on creation
  //     when loading searchParams from child component
  created() {
    this.loadList();
  },

  methods: {
    exportData(type) {
      const params = this.search_params;
      this.$store.dispatch('todos/exportData', { exportType: type, params })
        .then(() => {
          this.$store.dispatch(
            "snackbar/showSuccess",
            this.$t("snack.export_success")
          );
        })
        .catch((error) => {
          console.error('Export failed:', error);
          this.$store.dispatch(
            "snackbar/showSuccess",
            this.$t("snack.export_error")
          );
        });
    },
    changeP(search_params) {
      this.search_params = search_params;
      this.loadList();
    },
    loadList() {
      const payload = {
        ...this.search_params,
        ...this.paginationOptions
      };

      const actions = [
        () => this.$store.dispatch("affectations/getUndoneInternalRepairs", payload),
        () => this.$store.dispatch("affectations/getUndoneExternalRepairs", payload),
        () => this.$store.dispatch("todos/getUndone", payload),
        () => this.$store.dispatch("todos/getDone", payload)
      ];

      Promise.all(actions.map(action => action()))
        .then(([internalItems, externalItems, undoneTasks, doneTasks]) => {
          this.internal_affs = internalItems;
          this.external_affs = externalItems;
          this.tasks = undoneTasks;
          this.tasksDone = doneTasks;
        })
        .catch((error) => {
          console.error("Error loading lists:", error);
        });
    },


    bgClass(item) {
      // Compute bg classes (applied on each data row)
      var classes = [];
        let it_ = item.item
      if (!isNone(it_.internal_repair)) {
        if (moment(it_.internal_repair.created_at) < this.limit_3) {
          classes.push("creation_late_int");
        }
      } else if (!isNone(it_.external_repair)) {
        // Actual step
        if (isNone(it_.external_repair.sent_at)) {
          classes.push("sent_state");
        } else if (isNone(it_.external_repair.quotation_at)) {
          classes.push("quotation_state");
        } else if (isNone(it_.external_repair.agreed_at)) {
          classes.push("agreed_state");
        } else if (isNone(it_.external_repair.returned_at)) {
          classes.push("returned_state");
        }

        // Lateness
        if (moment(it_.external_repair.returned_at) < this.limit_10) {
          classes.push("returned_late");
        } else if (moment(it_.external_repair.agreed_at) < this.limit_3) {
          classes.push("agreed_late");
        } else if (moment(it_.external_repair.quotation_at) < this.limit_3) {
          classes.push("quotation_late");
        } else if (moment(it_.external_repair.sent_at) < this.limit_3) {
          classes.push("sent_late");
        } else if (moment(it_.external_repair.created_at) < this.limit_3) {
          classes.push("creation_late");
        }
      } else {
        // aka task
        
        classes.push("task_line");
        if (moment(it_.created_at) < this.limit_10) {
          classes.push("task_late");
        }
      }
      return {class: classes.join(" ")}
    },

    test(){
      return "default-class";
    },
    editIRBtn(item) {
      this.$store
        .dispatch(`internal_repairs/updateEdit`, item.internal_repair)
        .then(() => {
          this.$emit("editItem");
          this.editInternalRepair = true;
        });
    },

    editERBtn(item) {
      this.$store
        .dispatch(`external_repairs/updateEdit`, item.external_repair)
        .then(() => {
          this.$emit("editItem");
          this.editExternalRepair = true;
        });
    },

    _initTask(fullTask = true) {
      if (!isNone(this.user)) {
        this.$store.dispatch("users/setSearch", this.user);
        this.task_edit.assigned_to_id = this.user.id;
      }
      this.$emit("editItem");
      if (fullTask) {
        this.editTask = true;
      } else {
        this.editTaskDate = true;
      }
    },

    editTaskBtn(item) {
      if (!isNone(item)) {
        this.$store.dispatch(`todos/updateEdit`, item).then(() => {
          if (!isNone(item.producer_id)) {
            this.$store
              .dispatch("producers/initSearch", { item_ids: item.producer_id })
              .then(() => {
                this._initTask();
              });
          } else {
            this._initTask();
          }
        });
      } else {
        this._initTask();
      }
    },

    saveIREvent() {
      const index = this.internal_affs.findIndex(
        (obj) => obj.internal_repair.pk === this.internal_repair_edit.pk,
      );
      if (index !== -1) {
        this.internal_affs[index].internal_repair = this.internal_repair_edit;
      }
      return this.$store
        .dispatch(`affectations/editInternalRepair`, this.internal_repair_edit)
        .then(() => {
          this.$refs.editDialogIR.closeDialog();
        });
    },

    saveEREvent() {
      const index = this.external_affs.findIndex(
        (obj) => obj.external_repair.pk === this.external_repair_edit.pk,
      );
      if (index !== -1) {
        this.external_affs[index].external_repair = this.external_repair_edit;
      }
      return this.$store
        .dispatch(`affectations/editExternalRepair`, this.external_repair_edit)
        .then(() => {
          if (this.askNextAff){
            let clean_aff = {...this.$store.state.affectations.edit}
            delete clean_aff.producer;
            delete clean_aff.unit;
            this.$store.dispatch(
              'affectations/addItem', 
              clean_aff)
          }
          this.$refs.editDialogER.closeDialog();
        });
    },


    saveTaskEvent() {
      delete this.task_edit.producer;
      delete this.task_edit.created_by;
      this.task_edit.assigned_to_id=this.task_edit.assigned_to.id // trabalenguas
      delete this.task_edit.assigned_to;
        if (isNone(this.task_edit.pk)) {
          return this.$store
            .dispatch(`todos/addItem`, this.task_edit)
            .then(() => {
              slicyUpdate(this.tasks, this.task_edit);
              this.$refs.editDialogTask.closeDialog();
            });
        }
      slicyUpdate(this.tasks, this.task_edit);
      return this.$store.dispatch(`todos/editItem`, this.task_edit).then(() => {
        this.$refs.editDialogTask.closeDialog();
      });
    },

    saveTaskDateEvent() {
      slicyUpdate(this.tasks, this.task_edit);
      return this.$store
        .dispatch(`todos/editItem`, this.task_edit)
        .then((item) => {
          slicyRemove(this.tasks, item);
          this.$refs.editDialogTaskDate.closeDialog();
        });
    },

    cleanupERDialog() {
      this.editExternalRepair = false;
      this.askNextAff = false
      this.$store.dispatch(
        `external_repairs/updateEdit`,
        this.$store.state["external_repairs"].struct,
      );
    },
    cleanupStockDialog(){
      this.editToStock = false;
    },

    cleanupIRDialog() {
      this.editInternalRepair = false;
      this.$store.dispatch(
        `internal_repairs/updateEdit`,
        this.$store.state["internal_repairs"].struct,
      );
    },

    cleanupTaskDialog() {
      this.editTask = false;
      this.$store.dispatch(
        `todos/updateEdit`,
        this.$store.state["todos"].struct,
      );
    },

    cleanupTaskDateDialog() {
      this.editTaskDate = false;
      this.$store.dispatch(
        `todos/updateEdit`,
        this.$store.state["todos"].struct,
      );
    },

    internal_repaired(item) {
      //set editedItem
      let base_aff = {
        'unit': item.unit,
        'unit_id':item.unit_id,
        'kind': 'ss'
      }

      this.$store
        .dispatch(`affectations/updateEdit`, base_aff)
        .then(() => {
          this.$emit("editItem");
     this.editToStock = true
        });

    },

    saveToStock() {
      const index = this.internal_affs.findIndex(
        (obj) => obj.unit_id === this.$store.state.affectations.edit.unit_id,);
      return this.$store
        .dispatch(`affectations/addItem`, this.$store.state.affectations.edit)
        .then((item) => {
          if (index !== -1){
            this.internal_affs.splice(index, 1)
          };
          this.$refs.editDialogToStock.closeDialog();
          this.editToStock = false
        });
    },
    
    internal_trashed(item) {
      const index = this.internal_affs.findIndex((i) => i.pk === item.pk);
      alertPopup(capitalize(this.$t("dialog.to_trash_confirm"))).then(
        (result) => {
          if (result && this.internal_affs.splice(index, 1)) {
            this.$store.dispatch(
              `internal_repairs/trashed`,
              item.internal_repair.pk,
            );
          }
        },
      );
    },

    external_trashed(item) {
      const index = this.external_affs.findIndex((i) => i.pk === item.pk);
      alertPopup(capitalize(this.$t("dialog.to_trash_confirm"))).then(
        (result) => {
          if (result && this.external_affs.splice(index, 1)) {
            this.$store.dispatch(
              `external_repairs/trashed`,
              item.external_repair.pk,
            );
          }
        },
      );
    },
    viewUnit(item) {
        let query = {
          part: String(item.unit_id).toString(),
        };
        this.$router.push({ name: "unit", query: query });
    },


    close_todo(item) {
      item.fulfilled_at = moment().format("yyyy-MM-DD");
      this.$store.dispatch(`todos/updateEdit`, item).then(() => {
        this._initTask(false);
      });
    },

    remove_todo(item) {
      const index = this.tasks.findIndex((i) => i.pk === item.pk);
      alertPopup(capitalize(this.$t("dialog.remove_todo_confirm"))).then(
        (result) => {
          if (result && this.tasks.splice(index, 1)) {
            this.$store.dispatch(`todos/deleteItem`, item.pk);
          }
        },
      );
    },
    extRepUpdate(val, item){

      if (item.returned_at){
      const aff = this.external_affs.find((i) => i.external_repair_id === item.pk);
        // add consistent time to date - avoid disorder when several aff same day

      let initDate = new Date(item.returned_at);
      let now = new Date();
      initDate.setHours(now.getHours());
      initDate.setMinutes(now.getMinutes());

      let base_aff = {
            'unit': aff.unit,
          'unit_id': aff.unit_id,
          'date': initDate,
        }
        this.$store.dispatch('affectations/updateEdit', base_aff)
      this.askNextAff = true;
      }
    }
  },

  data() {
    return {
      editInternalRepair: false,
      editToStock: false,
      editExternalRepair: false,
      editTask: false,
      editTaskDate: false,
      askNextAff:false,
      internal_affs: [],
      external_affs: [],
      tasks: [],
      tasksDone: [],
      actionEdit: false,
      moduleName: "affectations",
      paginationOptions: {
        "page": 1,
        "size":10
      },
      headersInternal: [
        {
          title: this.$t("stock_parts.name"),
          key: "unit.stock_part.name",
        },
        { title: this.$t("stock_parts.ref"), key: "unit.stock_part.ref" },
        { title: this.$t("units.serial", 1), key: "unit.serial" },
        {
          title: this.$t("internal_repairs.description"),
          key: "internal_repair.description",
        },
        {
          title: this.$t("internal_repairs.created_at"),
          key: "internal_repair.created_at",
        },
        {
          title: this.$t("internal_repairs.assigned_to"),
          key: "internal_repair.assigned_to.name",
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      headersExternal: [
        {
          title: this.$t("stock_parts.name"),
          key: "unit.stock_part.name",
        },
        { title: this.$t("stock_parts.ref"), key: "unit.stock_part.ref" },
        { title: this.$t("units.serial", 1), key: "unit.serial" },
        {
          title: this.$t("external_repairs.created_at"),
          key: "external_repair.created_at",
        },
        {
          title: this.$t("external_repairs.assigned_to"),
          key: "external_repair.assigned_to.name",
        },
        {
          title: this.$t("external_repairs.sent_at"),
          key: "external_repair.sent_at",
        },
        {
          title: this.$t("external_repairs.quotation_at"),
          key: "external_repair.quotation_at",
        },
        {
          title: this.$t("external_repairs.agreed_at"),
          key: "external_repair.agreed_at",
        },
        {
          title: this.$t("external_repairs.returned_at"),
          key: "external_repair.returned_at",
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      headersTask: [
        { title: this.$t("generic.created_at"), key: "created_at" },
        { title: this.$t("generic.created_by"), key: "created_by.name" },
        { title: this.$t("todos.assigned_to"), key: "assigned_to.name" },
        {
          title: this.$t("producers.label", 1),
          key: "producer.display_name",
        },
        { title: this.$t("todos.description"), key: "description" },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      headersTaskDone: [
        { title: this.$t("generic.created_at"), key: "created_at" },
        { title: this.$t("generic.created_by"), key: "created_by.name" },
        { title: this.$t("todos.assigned_to"), key: "assigned_to.name" },
        {
          title: this.$t("producers.label", 1),
          key: "producer.display_name",
        },
        { title: this.$t("todos.description"), key: "description" },
        { title: this.$t("todos.fulfilled_at"), key: "fulfilled_at" },

      ],
      footerProps: {
        "items-per-page-options": [20, 50, -1],
        "items-per-page-text": this.$t("rows_per_page"),
      },
      limit_3: moment().startOf("day").subtract(3, "days"),
      limit_10: moment().startOf("day").subtract(10, "days"),
    };
  },
};
</script>

<style>
tr.task_line td:nth-child(5) {
  white-space: pre-wrap;
}
 
tr.creation_late_int td:nth-child(5) {
  background-color: #f2c2bd !important;
}
tr.creation_late td:nth-child(4) {
  background-color: #f2c2bd !important;
}
tr.sent_late td:nth-child(6) {
  background-color: #f2c2bd !important;
}
tr.quotation_late td:nth-child(7) {
  background-color: #f2c2bd !important;
}
tr.agreed_late td:nth-child(8) {
  background-color: #f2c2bd !important;
}
tr.returned_late td:nth-child(9) {
  background-color: #f2c2bd !important;
}
tr.task_late td:nth-child(1) {
  background-color: #f2c2bd !important;
}
 
tr.sent_state td:nth-child(6) {
  background-color: #efc8fe;
}
tr.quotation_state td:nth-child(7) {
  background-color: #efc8fe;
}
tr.agreed_state td:nth-child(8) {
  background-color: #efc8fe;
}
tr.returned_state td:nth-child(9) {
  background-color: #efc8fe;
}
</style>
