<template>
    <v-container fluid class="d-flex flex-wrap">
        <v-row>
            <v-col
                v-for="(item, power_station_name) in items"
                :key="power_station_name"
                :cols="12"
                :md="6"
                :lg="2"
                :xl="2"
                class="full-height"
            >
                <MachineButton
                    :item="item"
                    :power_station_name="power_station_name"
                />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import MachineButton from '@/components/MachineButton.vue';

    export default {
        components: {
            MachineButton,
        },
        data() {
            return {
                items: {},
            };
        },
        computed: {
            power_station_ids() {
                const ids = this.$route.query.power_station_ids;
                if (ids) {
                    return ids.split(",").map(Number);
                } else {
                    return [];
                }
            },
        },
        methods: {
            refreshPage() {
                this.$store
                    .dispatch('machines/get_solar_status_producer_items', { power_station_ids: this.power_station_ids })
                    .then((data) => {
                    if (data && typeof data === 'object') {
                        this.items = data;
                    } else {
                        console.error("Expected data to be an object, but got", data);
                        this.items = {};
                    }
                    })
                    .catch((error) => {
                        console.error("Error fetching items:", error);
                    });

            },
        },
        mounted() {
            this.refreshInterval = setInterval(() => {
                this.refreshPage();
            }, 60000);
            this.refreshPage();
        },
        beforeUnmount() {
            clearInterval(this.refreshInterval);
        },
    };
</script>


<style scoped>
    .full-screen-grid {
        height: 100vh;
        display: flex;
        flex-direction: column;
        padding: 2px;
        overflow: hidden;
    }

    .v-row {
        flex-grow: 1;
    }

    .my-button {
        width: 100%;
        height: 100px;
    }

</style>
