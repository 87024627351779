<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud v-bind:headers="headers" v-bind:moduleName="moduleName">
          <template v-slot:edit_content>
            <TodoForm v-bind:askRisk="false"></TodoForm>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import TodoForm from "@/components/TodoForm";

export default {
  components: {
    Crud: Crud,
    TodoForm: TodoForm,
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  data() {
    return {
      moduleName: "todos",
      headers: [
        {
          title: this.$t("producers.label"),
          key: "producer.display_name",
        },
        { title: this.$t("todos.description"), key: "description" },
        {
          title: this.$t("todos.assigned_to"),
          key: "assigned_to.first_name",
        },
        {
          title: this.$t("todos.assigned_to"),
          key: "assigned_to.last_name",
        },
        { title: this.$t("todos.fulfilled_at"), key: "fulfilled_at" },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      autocompleteSearch: null,
    };
  },
};
</script>
