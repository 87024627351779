<template>
    <Machine></Machine>
</template>
<script>
    import Machine from './MachineWind.vue';

    export default {
        components: {
            Machine
        },

    }
</script>