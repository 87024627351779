<template>
    <v-container fluid>
        <v-card class="mb-4" outlined v-if="item">
            <!-- Data producer --> 
            <v-card-title class="d-flex justify-center align-center">
                <v-icon left class="mr-2">mdi-domain</v-icon>
                <span class="text-h5 font-weight-bold text-center">{{ this.power_station_name }}</span>
            </v-card-title>
            
            <v-card-subtitle class="d-flex justify-end">
                <span class="text-h4 font-weight-bold">{{ formatNumber(item.total_power) }} {{ $t("unity.kw") }}</span>
            </v-card-subtitle>
            <v-row dense>
                <v-col
                    v-for="(producer, key) in item.producers"
                    :key="key"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                >
                    <v-card cols="3">
                        <v-card-title>
                            <v-icon :color="producer.worst_status_color" left>mdi-power</v-icon>
                            {{ producer.display_name }}
                        </v-card-title>
                        <v-card-subtitle>
                            {{ $t("generic.status") }}:
                            <v-chip
                                :color="producer.worst_status_color"
                                dark
                                small
                            >
                                {{ producer.worst_status_color }}
                            </v-chip>
                        </v-card-subtitle>
                        <v-card-text>
                            <v-list dense>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-icon>mdi-flash</v-icon>
                                        {{ $t("telemetry.power") }}: {{ formatNumber(producer.latest_power) }} {{ $t("unity.kw") }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-icon>mdi-weather-windy</v-icon>
                                        {{ $t("telemetry.wind") }}: {{ formatNumber(producer.latest_wind) }} {{ $t("unity.ms") }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-icon>mdi-clock</v-icon>
                                        {{ $t("telemetry.latest_time_delta") }}: {{ producer.time_delta_latest_telemetry }} {{ $t("unity.minutes", { count: producer.time_delta_latest_telemetry }) }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>

                    <!-- Telemetries --> 
                    <div class="flex-grow-1 p-2" v-if="producer.telemetries && producer.telemetries.length">
                        <h3>{{ $t("telemetry.label") }}:</h3>
                        <v-list dense>
                            <v-list-item
                                v-for="item in producer.telemetries"
                                :key="item.time_delta"
                                class="mb-2"
                            >
                                <v-card :color="item.status_curve ? 'green lighten-5' : 'red lighten-5'" outlined>
                                    <v-card-title class="telemetry-card-title">
                                        {{ formatDateTime(item.time) }}
                                    </v-card-title>
                                    <v-card-text>
                                        <div><strong>{{ $t("telemetry.power") }}:</strong> {{ formatNumber(item.power) }} {{ $t("unity.kw") }}</div>
                                        <div><strong>{{ $t("telemetry.wind") }}:</strong> {{ formatNumber(item.wind) }} {{ $t("unity.kmh") }}</div>
                                        <div><strong>{{ $t("telemetry.time_delta") }}:</strong> {{ formatNumber(item.time_delta) }} {{ $t("unity.minutes", { count: item.time_delta }) }}</div>
                                    </v-card-text>
                                </v-card>
                            </v-list-item>
                        </v-list>
                    </div>
                    <!-- Alarms -->
                    <div v-if="producer.alarms && producer.alarms.length">
                        <h3>{{ $t("alarms.label") }}:</h3>
                        <v-row>
                            <v-col v-for="item in producer.alarms" :key="item.id">
                                <v-card :color="getColorAlarmCriticality(item.alarm_code__criticality)">
                                    <v-card-title>{{ item.alarm_code__name }}</v-card-title>
                                    <v-card-text>
                                        <div>{{ $t("alarms.description") }}: {{ item.description }}</div>
                                        <div>{{ $t("alarms.code") }}: {{ item.alarm_code__code }}</div>
                                        <div>{{ $t("generic.date") }}: {{ formatDate(item.time) }}</div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>

                    <!-- Limitations -->
                    <div v-if="producer.limitations && producer.limitations.length">
                        <h3>{{ $t("limitations.label") }}:</h3>
                        <v-row>
                            <v-col
                                v-for="item in producer.limitations"
                                :key="item.id"
                                cols="6"
                                sm="6"
                                md="4"
                            >
                                <v-card>
                                    <v-card-text>
                                        <div>{{ $t("limitations.start") }}: {{ formatDate(item.start) }}</div>
                                        <div>{{ $t("limitations.end") }}: {{ formatDate(item.end) }}</div>
                                        <div>{{ $t("limitations.origin.label") }}: {{ item.origin }}</div>
                                        <div>{{ $t("limitations.comment") }}: {{ item.comment }}</div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
    import utils from "@/mixins/utils";

    export default {
    mixins: [utils],
    computed: {
        power_station_id() {
            return this.$route.query.power_station_id
        },
    },
    created() {
        this.item = this.$store.state.machines.item;
        if (this.item === null) {
            this.$store
                .dispatch('machines/get_wind_status_producer_items', { power_station_ids: [this.power_station_id] })
                .then((data) => {
                    this.power_station_name = Object.keys(data)[0];
                    this.item = data[this.power_station_name] ?? null;
                })
                .catch((error) => {
                    console.error("Error fetching items:", error);
                });
        }else{
            this.power_station_name = Object.keys(this.item)[0];
            this.item = this.item[this.power_station_name];
        }
    },
    methods: {
        formatNumber(value) {
            return Number(value).toFixed(2);
        },
        formatDate(dateStr) {
            const options = {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            };
            return new Date(dateStr).toLocaleDateString(undefined, options);
        },
        formatDateTime(dateStr) {
            const options = {
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            };
            
            return new Date(dateStr).toLocaleString(undefined, options);
        },
        formatTimeLabel(time) {
            return time.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
        },
        getColorAlarmCriticality(criticality) {
            switch(criticality) {
                case 'ok':
                    return 'green lighten-5';
                case 'warn':
                    return 'yellow lighten-5';
                case 'crit':
                    return 'red lighten-5';
                default:
                    console.error('Default color alarm no criticality');
                    return 'grey lighten-5';
                }
        },
    },
    data() {
        return {
            item: null,
        };
    }

    };
</script>

<style scoped>
    .v-card {
        min-height: 200px;
    }
    .telemetry-title {
        font-size: 1.5rem;
        margin-bottom: 16px;
        font-weight: bold;
        text-align: center;
    }

    .telemetry-card-title {
        font-weight: bold;
        font-size: 1.1rem;
        padding-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .telemetry-date {
        color: black;
    }

    .telemetry-data div {
        margin: 8px 0;
        font-size: 0.95rem;
    }

    .telemetry-card-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .status-icon {
        font-size: 1.5rem;
    }

    .status-text {
        font-size: 1rem;
        margin-left: 8px;
    }

    .text-success {
        color: green;
    }

    .text-error {
        color: red;
    }
</style>
