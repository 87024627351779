<template>
  <v-container class="mb-5 pa-3">
    <v-toolbar flat color="white" class="my-3 px-2" elevation="1">
      <v-tooltip location="top" :text="$filters.capitalize( $t(`${moduleName}.actions.back`) )">
        <template v-slot:activator="{ on }">
          <v-icon v-bind="on" class="mr-2" @click="backBtn()"
            >mdi-arrow-left-drop-circle-outline</v-icon
          >
        </template>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-toolbar-title style="font-weight: bolder">{{
        $filters.capitalize($t(pageTitle, 1) )
      }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn color="success" @click="saveItem()">{{
        $filters.capitalize($t("save") )
      }}</v-btn>
    </v-toolbar>

    <v-row>
      <v-col cols="6" md="4">
        <v-autocomplete
          v-model="editedItem.producer_id"
          :items="$store.state.producers.all"
          item-title="display_name"
          item-value="pk"
          :label="$filters.capitalize($t('producers.label', 1) )"
          :loading="$store.state.producers.isSearching"
          :filter="filterProducers"
          append-icon="mdi-text-box-search"
          :clearable="!disabledOnEdit"
          :disabled="disabledOnEdit"
          variant="solo"
          return-object
          @update:modelValue="selectProducer"
          @click:clear="editedItem.producer_id=undefined"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{
                  $filters.capitalize($t("search_for", { noun: $t("producers.label", 1) })
                    )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6" md="4">
        <DatePicker
          v-bind:label="'intervention_reports.date'"
          v-bind:value="editedItem.date"
          v-bind:minimalValue="minimalValue"
          @input="editedItem.date = $event"
        >
        </DatePicker>
      </v-col>

      <v-col cols="6" md="4">
        <v-select
          v-model="editedItem.status"
          :items="reportStatusItems"
          item-title="name"
          item-value="id"
          :label="$filters.capitalize($t('intervention_reports.status.label') )"
          variant="solo"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="5" md="2">
        <v-text-field
          v-model="editedItem.number"
          :label="$filters.capitalize($t('intervention_reports.number') )"
        ></v-text-field>
      </v-col>
      <v-col cols="7" md="5">
        <v-autocomplete
          v-model="editedItem.alarm_code_id"
          :items="$store.state.alarm_codes.search"
          item-title="name"
          item-value="pk"
          :label="$filters.capitalize($t('alarm_codes.label', 1) )"
          v-model:search="autocompleteSearchAlarmCode"
          :loading="$store.state.alarm_codes.isSearching"
          append-icon="mdi-text-box-search"
          :disabled="!showItemEdit"
          :clearable="true"
          variant="solo"
          no-filter
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{
                  $filters.capitalize($t("search_for", { noun: $t("alarm_codes.label", 1) })
                    )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="data">
            <v-list-item @click="data.props.onClick">
              <span
                v-if="
                  !['', null, undefined].includes(data.item.raw.code) &&
                  !['', null, undefined].includes(data.item.raw.level)
                "
              >
                {{ data.item.raw.level }} / n°{{ data.item.raw.code }} &ndash;
                {{ data.item.raw.name }}
              </span>
              <span v-else-if="!['', null, undefined].includes(data.item.raw.code)">
                n°{{ data.item.raw.code }} &ndash; {{ data.item.raw.name }}
              </span>
              <span v-else-if="!['', null, undefined].includes(data.item.raw.level)">
                {{ data.item.raw.level }} / {{ data.item.raw.name }}
              </span>
              <span v-else>
                {{ data.item.raw.name }}
              </span>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="5">
        <v-autocomplete
          v-model="editedItem.partner_ids"
          :items="$store.state.users.all"
          item-title="name"
          item-value="id"
          :label="$filters.capitalize($t('intervention_reports.partner', 10) )"
          append-icon="mdi-text-box-search"
          clearable
          variant="solo"
          cache-items
          chips
          deletable-chips
          multiple
          return-object
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{
                  $filters.capitalize($t("search_for", { noun: $t("users.label", 1) }) )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <v-textarea
          v-model="editedItem.cause"
          :label="$filters.capitalize($t('intervention_reports.cause') )"
          :row-height="18"
          append-outer-icon="mdi-chevron-right"
          auto-grow
          variant="solo"
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="4">
        <v-textarea
          v-model="editedItem.action"
          :label="$filters.capitalize($t('intervention_reports.action') )"
          :row-height="18"
          append-outer-icon="mdi-chevron-right"
          auto-grow
          variant="solo"
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="4">
        <v-textarea
          :disabled="disabledOnEdit"
          v-model="editedItem.todo"
          :label="$filters.capitalize($t('intervention_reports.todo') )"
          :row-height="18"
          auto-grow
          variant="solo"
          ></v-textarea>
          <v-checkbox
          :disabled="disabledOnEdit"
          color="red"
          v-model="editedItem.todo_is_risk"
          :label="$filters.capitalize($t(`risks.is_risk`) )"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row class="text-center" v-show="!showItemEdit">
      <v-col
        class="service_message"
        v-show="['', null, undefined].includes(editedItem.producer_id)"
      >
        {{ $filters.capitalize($t("intervention_reports.edition.select_producer") ) }}
      </v-col>
      <v-col
        class="service_message"
        v-show="!['', null, undefined].includes(editedItem.producer_id)"
      >
        {{ $filters.capitalize($t("intervention_reports.edition.no_stockage") ) }}
      </v-col>
    </v-row>

    <v-row v-show="showItemEdit">
      <v-col cols="12" class="px-3 py-0 intertitre">
        <v-icon small>mdi-send</v-icon>
        {{
          $filters.capitalize($t("intervention_reports.affectation.old_part", 10) )
        }}</v-col
      >

      <template v-for="(part, index) in removedParts" :key="index">
        <Removed
          v-bind:ordinal="index"
          v-bind:producerId="editedItem.producer_id"
          v-bind:editMode="!isCreation"
          v-bind:addedMode="false"
          v-on:remove_affected="removeRemovedUnit"
        ></Removed>
      </template>
    </v-row>

    <v-row
      v-show="showItemEdit"
      align="center"
      class="option_line"
      justify="center"
      align-self="center"
    >
      <v-col cols="8" sm="9" class="pt-0 pb-5">
        <v-row>
          <v-col
            v-if="!disabledOnEdit" class="px-0 pt-0 pb-5"
          >
            <!-- v-bind:disabled="editedItem.producer_id === undefined" -->
            <EditDialog
              disabled
              v-bind:model="newUnitToRemove"
              v-bind:moduleName="moduleName"
              v-bind:formTitle="$t('intervention_reports.actions.remove_new_unit')"
              v-bind:btnName="$t('intervention_reports.actions.remove_new_unit')"
              v-bind:dataValidation="validatePartForm"
              @add="addNewUnit(true)"
              @save="saveNewUnit(true)"
              @close="cleanupNewUnitToRemove"
              ref="editNewUnitToRemoveRef"
            >
              <template v-slot:edit_content>
                <PartForm
                  v-bind:producerId="editedItem.producer_id"
                  v-bind:askPlace="false"
                  v-bind:partFilter="'un'"
                ></PartForm>
              </template>
            </EditDialog>
          </v-col>

          <v-col
            v-if="!disabledOnEdit" class="d-flex align-center pt-0 pb-5"
          >
            <v-btn color="secondary" @click="addRemovedUnit()">
              {{ $filters.capitalize($t("intervention_reports.actions.remove_unit") ) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        v-if="!disabledOnEdit" cols="4" sm="3" class="pt-0 pb-10"
      >
<!---        <ScanCodeButton
          v-bind:btnName="'scan'"
          @fetchedUnit="removedFetched"
        ></ScanCodeButton> 
--->
      </v-col>
    </v-row>

    <v-row v-show="showItemEdit">
      <v-col class="px-3 py-0 intertitre">
        <v-icon small>mdi-send</v-icon>
        {{
          $filters.capitalize($t("intervention_reports.affectation.consumed_part", 10) )
        }}</v-col
      >

      <template v-for="(part, index) in consumedParts" :key="index">
        <Consumed
          v-bind:ordinal="index"
          v-bind:stockageId="stockageId"
          v-bind:producerTypeId="producerTypeId"
          v-bind:editMode="!isCreation"
          v-on:remove_consumed="removeConsumed"
        ></Consumed>
      </template>
    </v-row>

    <v-row
      v-show="showItemEdit"
      class="option_line"
    >
      <v-col
        v-if="!disabledOnEdit"
        cols="12"
        class="px-3 pt-0 pb-9"
      >
        <v-btn color="secondary" @click="addConsumed()">
          {{ $filters.capitalize($t("intervention_reports.actions.add_consumable") ) }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-show="showItemEdit">
      <v-col cols="12" class="px-3 py-0 intertitre">
        <v-icon small>mdi-send</v-icon>
        {{
          $filters.capitalize($t("intervention_reports.affectation.new_part", 10) )
        }}</v-col
      >

      <template v-for="(part, index) in addedParts" :key="index">
        <Added
          v-bind:ordinal="index"
          v-bind:producerId="editedItem.producer_id"
          v-bind:stockageId="stockageId"
          v-bind:producerTypeId="producerTypeId"
          v-bind:editMode="!isCreation"
          v-bind:addedMode="true"
          v-on:remove_affected="removeAddedUnit"
        ></Added>
      </template>
    </v-row>

    <v-row
      v-show="showItemEdit"
      align="center"
      class="option_line"
      justify="center"
      align-self="center"
    >
      <v-col cols="8" sm="9" class="pt-0 pb-5">
        <v-row>
          <v-col
            v-if="!disabledOnEdit" class="px-0 pt-0 pb-5">
            <EditDialog
              disabled
              v-bind:model="newUnit"
              v-bind:moduleName="moduleName"
              v-bind:formTitle="$t('intervention_reports.actions.add_new_unit')"
              v-bind:btnName="$t('intervention_reports.actions.add_new_unit')"
              v-bind:dataValidation="validatePartForm"
              @add="addNewUnit(false)"
              @save="saveNewUnit(false)"
              @close="cleanupNewUnit"
              ref="editNewUnitRef"
            >
              <template v-slot:edit_content>
                <PartForm
                  v-bind:producerId="editedItem.producer_id"
                  v-bind:askPlace="false"
                  v-bind:partFilter="'un'"
                ></PartForm>
              </template>
            </EditDialog>
          </v-col>

          <v-col
            v-if="!disabledOnEdit" class="d-flex align-center pt-0 pb-5">
            <v-btn color="secondary" @click="addAddedUnit()">
              {{ $filters.capitalize($t("intervention_reports.actions.add_unit") ) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="!disabledOnEdit" cols="4" sm="3" class="pt-0 pb-10">
        <ScanCodeButton
        v-bind:btnName="'scan'"
        v-on:fetchedUnit="addedFetched"
        ></ScanCodeButton>
      </v-col>
    </v-row>
    <v-row>
      <h2 v-show='local_units_added.length>0'>
      TEMPORARY DISPLAY
      </h2>
      <v-divider></v-divider>
      <h3 v-show='local_units_added.length>0'>
      UNITS installed on machine
      </h3>
      <ul v-show='local_units_added.length>0'>
    <li> qr_code -- stock_part__name -- serial </li>
      <li v-for='add_ in local_units_added'>
        {{ add_.qr_code }} - {{ add_.stock_part.name }} - {{ add_.serial }}

      </li></ul>
      <v-divider></v-divider>
    </v-row>

    <v-row v-show="showItemEdit" class="d-flex justify-end pr-7">
      <v-btn color="success" @click="saveItem()">{{
        $filters.capitalize($t("save") )
      }}</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import PartForm from "@/components/PartForm";
import Removed from "@/components/intervention_report/Removed";
import Consumed from "@/components/intervention_report/Consumed";
import Added from "@/components/intervention_report/Added";
import DatePicker from "@/components/DatePicker";
import EditDialog from "@/components/EditDialog";
import ScanCodeButton from "@/components/qrcode/ScanCodeButton.vue";
import { capitalize, isNone } from "@/functions.js";
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";

export default {
  mixins: [utils, filters],

  components: {
    PartForm: PartForm,
    Removed: Removed,
    Consumed: Consumed,
    Added: Added,
    DatePicker: DatePicker,
    EditDialog: EditDialog,
    ScanCodeButton: ScanCodeButton,
  },

  props: {
    pk: { type: [Number, String], required: false, default: undefined },
  },

  computed: {
    editedItem: function () {
      return this.$store.state[`${this.moduleName}`].edition;
    },
    isCreation: function () {
      return this.pk? false:true;
    },
    pageTitle: function () {
      if (this.isCreation) {
        return "intervention_reports.edition.creation_label";
      }
      return "intervention_reports.edition.edition_label";
    },
    disabledOnEdit: function () {
      return this.pk? true:false;
    }, // TODO: temporary
    consumedParts: function () {
      return this.editedItem.consumed_parts;
    },
    removedParts: function () {
      return this.editedItem.removed_parts;
    },
    addedParts: function () {
      return this.editedItem.added_parts;
    },
    minimalValue: function () {
      // FIXME temporary disabled
      //if (!this.isFromStaff) { return this.limit_15.format('YYYY-MM-DD') }
      return undefined;
    },
    local_units: function () {
      return [... new Set( this.$store.state.units.local_items)]
    },
    local_units_added: function () {
      let added_ids = this.addedParts.map(a => a.pk)
      return this.local_units.filter(a => added_ids.includes(a.pk))
    },
  },

  methods: {
    removedFetched(item) {
      item=this.setObjectToId(item)
      // TODO: help user when thehy scan removed in added. that is if unit not affected to machine offer to have it as added instead and viceversa
      let index = this.removedParts.length - 1;
      this.$store.dispatch("units/checkLatest", item).then((latest) => {
        if (latest.producer_id != this.editedItem.producer_id) {
          this.affectToMachine(item)
        }
      });
      if (item !== undefined && this.$store.state.intervention_reports.edition.removed_parts.findIndex(x => x.id === item) !== -1) {
        const msg = this.$t('intervention_reports.edition.error_duplicated', {id: item})
        this.$store.dispatch('snackbar/showError', msg)
        return
      }
      if (this.removedParts[index].id == undefined){
        this.$store.dispatch("intervention_reports/updateRemovedParts", {
          index: index,
          item: { id: item },
        });
      } else {
        this.$store.dispatch("intervention_reports/addEmptyRemoved")
        this.removedFetched(item)
      }
    },
    affectToMachine(item){
      var aff = Object.assign({}, this.$store.state["affectations"].struct);
      aff["unit_id"] = item;
      aff["producer_id"] = this.editedItem.producer_id;
      this.$store
        .dispatch("affectations/affect", aff)
        .then(() => {});
    },
    setObjectToId(item){
      // cleaning object #notProud
      // TODO: set general solution for this. maybe in the store
      if (item && typeof(item)=='object') return item.pk
      return item
    },

    addedFetched(item) {
      item=this.setObjectToId(item)
      let index = this.addedParts.length - 1;
      this.$store.dispatch("units/checkLatest", item).then((latest) => {
        if (!isNone(latest.producer_id)) { // if the unit is already affected to another machine
          const msg = this.$t('intervention_reports.edition.error_installed', {id: item})
            this.$store.dispatch('snackbar/showError', msg)
            return
        } else {
          if (item !== undefined && this.$store.state.intervention_reports.edition.added_parts.findIndex(x => x.pk === item) !== -1) {
            const msg = this.$t('intervention_reports.edition.error_duplicated', {id: item})
            this.$store.dispatch('snackbar/showError', msg)
            return
          }
          if (this.addedParts[index].pk == undefined){
            // updateAddedParts must receive a category so we get it from the scanned unit
            let cat;
            if(this.$store.state.unit_codes.items[0].unit_id == item){ //making sure the unit from the store is the same as the one we are working with
              cat = this.$store.state.unit_codes.items[0].unit.stock_part.category_id
            }
            this.$store.dispatch('intervention_reports/updateAddedParts', { 
              index: index, 
              item: { pk: item, category: cat } })
          } else {
            this.$store.dispatch("intervention_reports/addEmptyAdded");
            this.addedFetched(item)
          }
        }
      });
    },
    saveItem() {
      var is_missing = [];
      if (this.editedItem.date === "") {
        is_missing.push(
          this.$t("intervention_reports.edition.missing.date"),
        );
      }
      if (this.editedItem.producer_id === null) {
        is_missing.push(
          this.$t("intervention_reports.edition.missing.producer_id"),
        );
      }
      if (this.editedItem.status === "") {
        is_missing.push(
          this.$t("intervention_reports.edition.missing.status"),
        );
      }
      if (this.editedItem.cause === "") {
        is_missing.push(
          this.$t("intervention_reports.edition.missing.cause"),
        );
      }
      if (this.editedItem.action === "") {
        is_missing.push(
          this.$t("intervention_reports.edition.missing.action"),
        );
      }
      for (var i = 0; i < this.removedParts.length; i++) {
        var aff = this.removedParts[i];
        if (aff.pk === undefined) {
          continue;
        }
        if (aff.kind === undefined) {
            aff.kind = 'ss'     // if undefined assigned to stock
//          is_missing.push(
//            this.$t("intervention_reports.edition.missing.kind"),
//          );
        }
      }

      if (is_missing.length > 0) {
        this.$store.dispatch(
          "snackbar/showWarning",
          this.$t("intervention_reports.edition.missing.sentence", {
            slice: is_missing.join(", "),
          }),
        );
        return false;
      }

      //cleaning data for back #shame
      this.editedItem.partner_ids = this.editedItem.partner_ids.map(partner => partner.id)
      delete this.editedItem.producer
      for (let thing of this.editedItem.consumed_parts) { if (!isNone(thing.place)) thing.place = thing.place.pk };
      for (let thing of this.editedItem.added_parts) { if(!isNone(thing.place)) thing.place=thing.place.pk}

      this.$store
        .dispatch(`${this.moduleName}/sendEdition`, this.pk)
        .then(() => {
          this.$router.push({ name: "intervention_reports" });
          this.$store.dispatch("intervention_reports/initEdition");
        });
    },

    addRemovedUnit() {
      this.$store.dispatch("intervention_reports/addEmptyRemoved");
    },

    removeRemovedUnit(ordinal) {
      this.$store.dispatch("intervention_reports/removeRemoved", ordinal);
    },

    addAddedUnit() {
      this.$store.dispatch("intervention_reports/addEmptyAdded");
    },

    removeAddedUnit(ordinal) {
      this.$store.dispatch("intervention_reports/removeAdded", ordinal);
    },

    addNewUnit(toRemove) {
      if (toRemove) {
        this.newUnitToRemove = true;
      } else {
        this.newUnit = true;
      }
    },

    validatePartForm() {
      const edit = this.$store.state.units.edit;
      if (isNone(edit.stock_part_id)) {
        const field = capitalize(this.$t("stock_parts.label", 1));
        return capitalize(this.$t("snack.missing", { field: field }));
      }
      if (isNone(this.defaultPlace) && isNone(edit.place_id)) {
        const field = capitalize(this.$t("places.label", 1));
        return capitalize(this.$t("snack.missing", { field: field }));
      }
      if (
        this.$store.state.unitItems_.includes(edit.stock_part.unity) &&
        isNone(edit.serial) && isNone(edit.qr_code)
      ) {
        return capitalize(
          this.$t("part_edition.missing.sentence", {
            slice: this.$t("part_edition.missing.serial"),
          }),
        );
      }
      return undefined;
    },

    saveNewUnit(doAffect, item) {
      // var item = Object.assign({}, this.$store.state.units.edit);
      if (!isNone(this.defaultPlace)) {
        item.place_id = this.defaultPlace;
      }

      return this.$store.dispatch("units/addItem", item).then((unit) => {
        if (doAffect) {
          var aff = Object.assign({}, this.$store.state["affectations"].struct);
          aff["date"] = moment();
          aff["unit_id"] = unit.pk;
          aff["producer_id"] = this.producer_id;
          this.$store.dispatch("affectations/addItem", aff).then(() => {});
          this.$refs.editNewUnitToRemoveRef.closeDialog();
          this.cleanupNewUnitToRemove();
        } else {
          this.$refs.editNewUnitRef.closeDialog();
          this.cleanupNewUnit();
        }
        this.$store.dispatch("units/addLocalItem", unit).then(() => {
          this.$store.dispatch(
            "snackbar/showSuccess",
            this.$t("snack.unit_added", { name: unit.stock_part.name }),
          );
          this.$store.dispatch("units/cleanupEdit");
        });
      });
    },

    cleanupNewUnit() {
      this.newUnit = false;
    },

    cleanupNewUnitToRemove() {
      this.newUnitToRemove = false;
    },

    addConsumed() {
      this.$store.dispatch("intervention_reports/addEmptyConsumed");
    },

    removeConsumed(ordinal) {
      this.$store.dispatch("intervention_reports/removeConsumed", ordinal);
    },

    backBtn() {
      this.user_id = parseInt(this.$route.query.user_id) || undefined;
      var query = {};
      if (!isNone(this.producer_id)) {
        query["producer_id"] = this.producer_id;
      }
      if (!isNone(this.user_id)) {
        query["user_id"] = this.user_id;
      }
      this.$store.dispatch('intervention_reports/cleanupEdition')
      this.$router.push({ name: "intervention_reports", query: query });
    },

    addEmpties() {
      if (
        this.editedItem.removed_parts.filter((x) => x.id === undefined)
          .length === 0 &&
        this.isCreation
      ) {
        this.$store.dispatch("intervention_reports/addEmptyRemoved");
      }
      if (
        this.editedItem.consumed_parts.filter((x) => x.id === undefined)
          .length === 0 &&
        this.isCreation
      ) {
        this.$store.dispatch("intervention_reports/addEmptyConsumed");
      }
      if (
        this.editedItem.added_parts.filter((x) => x.id === undefined).length ===
          0 &&
        this.isCreation
      ) {
        this.$store.dispatch("intervention_reports/addEmptyAdded");
      }
    },

    selectProducer(producer) {
      if (!isNone(producer)) {
        this.editedItem.producer_id = producer.pk;
        if (!isNone(producer.stockage_id)) {
          this.stockageId = producer.stockage_id;
        }
        this.producerTypeId = producer.producer_type_id;
        this.showItemEdit = !isNone(producer.stockage_id);
      }
    },
    checkIfThingInList(thing_pk, list_o_things){
        return list_o_things.find(thing => thing.pk == thing_pk)
    },
  },

  mounted() {
    // Preload a certain producer

    this.producer_id = parseInt(this.$route.query.producer_id) || undefined;
    let all = this.$store.state.producers.all;
    let thing = this.checkIfThingInList(this.producer_id, all);
    
    if (all.length <= 0) {
        this.$store.dispatch("producers/getAll").then((new_all)=> {
            if(!isNone(this.producer_id)) {
                thing = this.checkIfThingInList(this.producer_id, new_all);
                this.selectProducer(thing, new_all);
                }
            })
        }

    else if (!isNone(this.producer_id)) {
      this.selectProducer(thing, all);
    }

    if (this.isCreation) {
      this.$store.dispatch("intervention_reports/cleanupEdition").then(() => {
        this.$store.dispatch("intervention_reports/initEdition").then(() => {
          this.addEmpties();
        });
      });
      this.$store.dispatch("users/setEmptySearch");
    } else {
      this.$store
        .dispatch("intervention_reports/getEdition", this.pk)
        .then((ir) => {
          this.addEmpties();
          if (!isNone(ir.alarm_code_id)) {
            this.$store.dispatch("alarm_codes/initSearch", {
              item_ids: ir.alarm_code_id,
            });
          }
          this.showItemEdit = !isNone(ir.producer.stockage_id);
        });
    }
    if (this.$store.state.users.all.length >= 0) this.$store.dispatch('users/getAll')
  },

  watch: {
// second condition in each to avoid duplicate request, could be improved
    autocompleteSearchAlarmCode(val) {
      if (!this.$store.getters.canSearch("alarm_codes")) return;
      if (this.editedItem.alarm_code_id) return;
      this.delayedSearch(val, "alarm_codes/searchItems",{
        'value':val,
        'producer_type_id': this.producerTypeId,
      });
    },
    stockageId(val) {
      if(val == this.editedItem.stockage_id) return;
      this.$store.dispatch("stockages/getDefaultPlace", val).then((value) => {
        this.defaultPlace = value.pk;
      });
    },
  },

  data() {
    return {
      moduleName: "intervention_reports",
      reportStatusItems: [
        {
          name: capitalize(this.$t("intervention_reports.status.final")),
          id: "final",
        },
        {
          name: capitalize(this.$t("intervention_reports.status.tmp")),
          id: "tmp",
        },
        {
          name: capitalize(
            this.$t("intervention_reports.status.maintenance"),
          ),
          id: "maintenance",
        },
        {
          name: capitalize(this.$t("intervention_reports.status.none")),
          id: "none",
        },
      ],
      producer_id: undefined,
      subSelectId: null,
      autocompleteSearchAlarmCode: null,
      autocompleteSearchProducer: null,
      autocompleteSearchUser: null,
      added_parts: [],
      newUnit: false,
      newUnitToRemove: false,
      stockageId: null,
      producerTypeId: null,
      showItemEdit: false,
      defaultPlace: undefined,
      limit_15: moment().startOf("day").subtract(15, "days"),
    };
  },
};
</script>

<style>
.option_line {
  min-height: 2em;
}

.intertitre {
  font-weight: bold;
}
.intertitre i.v-icon {
  color: black;
  padding-right: 5px;
}

.v-select.v-input--is-disabled > .v-input__control > .v-input__slot {
  cursor: not-allowed;
}

.service_message {
  font-style: italic;
}
</style>
